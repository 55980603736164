import { fetchEventApi, fetchStreamApi, fetchUrlApi } from '../api';
import { getStreamFormat } from '../helpers';

export const SET_CONFIG = 'MEDIA_STREAMING/SET_CONFIG';
export const FETCH_EVENT_DATA_SUCCESS = 'MEDIA_STREAMING/FETCH_EVENT_DATA_SUCCESS';
export const UPDATE_STATUS = 'MEDIA_STREAMING/UPDATE_STATUS';
export const UPDATE_STREAM_DATA = 'MEDIA_STREAMING/UPDATE_STREAM_DATA';
export const UPDATE_URL = 'MEDIA_STREAMING/UPDATE_URL';
export const TOGGLE_PLAYER = 'MEDIA_STREAMING/TOGGLE_PLAYER';
export const RESET = 'MEDIA_STREAMING/RESET';

export const setConfig = (eventId, sportId, isInPlay, renderPlayer) => ({
  type: SET_CONFIG,
  eventId,
  sportId,
  isInPlay,
  renderPlayer,
});

export const fetchEventDataSuccess = (data) => ({
  type: FETCH_EVENT_DATA_SUCCESS,
  payload: data,
});

export const updateStatus = (status) => ({
  type: UPDATE_STATUS,
  status,
});

export const updateStreamData = (data) => ({
  type: UPDATE_STREAM_DATA,
  payload: data,
});

export const updateUrl = (url) => ({
  type: UPDATE_URL,
  url,
});

export const togglePlayer = () => ({
  type: TOGGLE_PLAYER,
});

export const reset = () => ({
  type: RESET,
});

export const fetchEventData = () => (dispatch, getState) => {
  const { streaming } = getState();

  return fetchEventApi(streaming.eventId, streaming.sportId)
    .then((data) => dispatch(fetchEventDataSuccess(data)))
    .catch(() => {});
};

export const fetchUrl = (url) => (dispatch) => {
  fetchUrlApi(url)
    .then((data) => {
      if (data.status === 403) return dispatch(updateStatus('location_restricted'));
      return data.json();
    })
    .then((data) => dispatch(updateUrl(data.hlsUrl)))
    .catch(() => dispatch(updateStatus('generic_error')));
};

export const fetchStream = () => (dispatch, getState) => {
  const { streaming } = getState();
  const format = getStreamFormat(streaming);

  return fetchStreamApi(streaming.eventId, streaming.sportId, streaming.providerId, format)
    .then((data) => {
      if (data.status === 401) return dispatch(updateStatus('unauthorized'));
      if (data.media_type.toLowerCase() === 'api') {
        dispatch(updateStreamData(data));
        return dispatch(fetchUrl(data.stream_url));
      }
      return dispatch(updateStreamData(data));
    })
    .catch(() => dispatch(updateStatus('generic_error')));
};

export const initialState = {
  eventId: null,
  sportId: null,
  isInPlay: false,
  providerId: null,
  status: null,
  isEntitled: false,
  renderPlayer: false,
  urlData: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        eventId: action.eventId,
        sportId: action.sportId,
        isInPlay: action.isInPlay || false,
        renderPlayer: action.renderPlayer || false,
      };

    case FETCH_EVENT_DATA_SUCCESS:
      return {
        ...state,
        providerId: action.payload.provider_id,
        status: action.payload.status.toLowerCase(),
        isEntitled: action.payload.status.toLowerCase() !== 'bet_and_watch',
        streamableFormats: action.payload.streamable_formats || [],
      };

    case UPDATE_STATUS:
      return {
        ...state,
        status: action.status.toLowerCase(),
      };

    case UPDATE_STREAM_DATA:
      return {
        ...state,
        urlData: action.payload,
      };

    case UPDATE_URL:
      return {
        ...state,
        urlData: {
          ...state.urlData,
          stream_url: action.url,
        },
      };

    case TOGGLE_PLAYER:
      return {
        ...state,
        renderPlayer: !state.renderPlayer,
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
};
